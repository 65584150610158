.about_me_container {
    font-family: var(--roboto);
    /* #272428 */
    background-color: var(--black-two);
    padding-bottom: 7rem;
    padding-left: 10%;
    padding-right: 20%;
    color: white;
}

.title_about {
    font-size: 3rem;
    padding-top: 4rem;
    padding-bottom: 1rem;
}

.information_about {
    font-size: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

/* Mobile Phones */
@media screen and (max-width: 767px) {
    .title_about {
        font-size: 2rem;
    }

    .information_about {
        font-size: 1.25rem;
        padding-bottom: 0.5rem;
    }
}

/* Tablets */
@media screen and (min-width: 768px) and (max-width: 1020px) {
    .information_about {
        font-size: 1.2rem;
    }
}