.footer_container {
    font-size: 1rem;
    padding-top: 1%;
    text-align: center;
    background-color: var(--black-one); 
}

.footer_text {
    font-family: var(--roboto);
    color: white;
    padding-bottom: 1%;
}

.animated_emoji {
    display: inline-block;
    animation: pulse 1s infinite;
}