.form_container {
    background-color: #fafafa;
    width: 40vw;
    padding: 5vh 4vw;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form_container > div {
    margin-bottom: 2vh;
}

.form_presentation_container h2 {
    text-align: center;
    font-weight: 900;
    font-size: 2rem;
    padding-bottom: 1rem;
}

.form_presentation_container p {
    font-size: 1.1rem;
    padding-bottom: 0.5rem;
}

.submit_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #3080e8;
    border: none;
    padding: 1vh 0;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-weight: 500;
    font-size: 1.25rem;
    transition: 0.3s ease-in-out;
}

.submit_button img {
    width: 3vw;
    height: auto;
    object-fit: cover;
}

.submit_button:hover {
    background-color: #1663c7;
}

/* Mobile Phones */
@media screen and (max-width: 767px) {
    .form_container {
        width: 80vw;
    }

    .form_presentation_container h2 {
        font-size: 1.5rem;
    }

    .submit_button img {
        width: 10vw;
        height: auto;
    }
}

/* Tablets */ 
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .form_container {
        width: 50vw;
    }

    .form_presentation_container h2 {
        font-size: 1.75rem;
    }

    .submit_button img {
        width: 5vw;
        height: auto;
    }
}