.soft_skills_container {
    color: white;
    padding: 5vh 5vw;
    background-color: var(--black-four);
}

.soft_skills_title {
    font-size: 3rem;
    padding-top: 0.5rem;
    text-align: center;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    background-color: white;
    color: black;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    transition: ease 4s ease;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.soft_skill_card {
    padding-top: 2vh;
    padding-bottom: 4vh;
}

.soft_skill_card svg {
    font-size: 5rem;
    margin-bottom: 1rem;
}

.soft_skill_card h3 {
    font-weight: bold;
    font-size: 1.5rem;
}

/* Mobile Phones */
@media screen and (max-width: 767px) {
    .soft_skills_title {
        font-size: 2rem;
    }
}