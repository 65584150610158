@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --roboto: 'Roboto', sans-serif;
    --black-one: rgba(0, 0, 0, 0.5);
    --black-two: rgba(10, 10, 9, 0.5);
    --black-three: rgba(20, 20, 18, 0.5);
    --black-four: rgba(30, 30, 27, 0.5);
    --black-five: rgba(40, 40, 36, 0.5);
    --black-six: rgba(50, 50, 44, 0.5);

    --black: #000000;
    --white: #FFFFFF;
}

* {
    font-family: var(--roboto);
}

body::-webkit-scrollbar {
    width: 1.5vh;
}

body::-webkit-scrollbar-track {
    background: black;
}

body::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 8px;
}

body::-webkit-scrollbar-thumb:hover {
    background: #F0ECE3;
}

#particles canvas {
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
}

.line {
    display: block;
    height: 0.25vh;
    background: white;
    margin: 0.5rem 5% 2rem 5%;
}