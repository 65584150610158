.projects_container {
    background-color: var(--black-six);
}

.projects_title_container {
    padding: 0 5vw;
}

.projects_title_container h2 {
    font-size: 3rem;
    padding-top: 5vh;
    text-align: center;
    color: white;
}

.projects_card_container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.card_project {
    background: white;
    width: 40rem;
    height: 19rem;
    border-radius: 10px;
    border: 8px solid white;
    position: relative;
    overflow: hidden;
    margin-bottom: 3rem;
}

.card_project:hover .card_project_body {
    right: 0;
}

.image_project {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    object-fit: cover;
}

.card_project_body {
    width: 100%;
    height: 100%;
    top: 0;
    right: -100%;
    position: absolute;
    backdrop-filter: blur(7px);
    border-radius: 15px;
    color: black;
    transition: 1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card_title_project {
    font-size: 3rem;
    font-weight: bold;
    margin: 15px 15px;
}

.card_information_project {
    margin-left: 20px;
    margin-right: 20px;
}

.buttons_card_container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.card_page_button, .card_repository_button {
    font-size: 1.25rem;
    cursor: pointer;
    width: 11rem;
    height: 2rem;
    border-radius: 0.3rem;
}

.card_page_button, .card_repository_button {
    color: #F0ECE3;
    border: 1px solid #F0ECE3;
    background: #333333;
}

.card_repository_button:hover, .card_page_button:hover {
    color: #333333;
    border: 1px solid #333333;
    background: #F0ECE3;
}

/* Mobile Phones */
@media screen and (max-width: 767px) {
    .projects_title_container h2 {
        font-size: 2rem;
    }

    .card_title_project {
        font-size: 1.3rem;
        margin-bottom: 7px;
    }

    .card_page_button, .card_repository_button {
        font-size: 1rem;
    }

    .card_information_project {
        display: none;
    }

    .card_project_body {
        border-radius: 5px;
    }

    .card_project {
        width: 90vw;
        height: auto;
        border: 3px;
    }
}