.education_container {
    background-color: var(--black-five);
    display: flex;
    flex-direction: column;
    padding: 5vh 5vw;
    color: white;
}

.title_education_container h2 {
    font-size: 3rem;
    padding-top: 0.5rem;
    text-align: center;
}

.education_items_container {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    flex-wrap: wrap;
}

.education_items_container li {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.education_items_container li img {
    width: 26vw;
    height: auto;
    padding: 5vh 5vh;
    background-color: white;
    border-radius: 1rem;
    transition: transform 0.3s ease-in-out;
}

.education_items_container li img:hover {
    box-shadow: 0px 16px 28px -8px black;
    transform: scale(1.05);
    transition: transform 1s ease-in-out;
}

.education_items_container li h3 {
    font-weight: bold;
    font-size: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
}

.education_items_container li p {
    font-weight: 300;
    font-size: 1.05rem;
}

/* Mobile Phones */
@media screen and (max-width: 767px) {
    .education_items_container li {
        padding-bottom: 5vh;
        width: 60vw;
    }

    .title_education_container h2 {
        font-size: 2rem;
    }

    .education_items_container li h3 {
        font-size: 1.5rem;
    }

    .education_items_container li img {
        width: 60vw;
    }

    .education_container {
        padding-bottom: 0;
    }
}

/* Tablets */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .education_items_container li {
        padding-bottom: 5vw;
    }

    .education_items_container li img {
        width: 55vw;
    }

    .education_container {
        padding-bottom: 0;
    }
}