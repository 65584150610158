.contact_me_form {
    display: flex;
    justify-content: space-around;
    background-color: var(--black-two);
    align-items: center;
    padding: 10vh 0;
}

.envelope_container {
    width: 40vw;
    height: auto;
}

/* Mobile Phones */
@media screen and (max-width: 767px) {
    .contact_me_form {
        padding: 5vh 0;
    }

    .envelope_container {
        display: none;
    }
}

/* Tablets */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .contact_me_form {
        padding: 4vh 0;
    }

    .envelope_container {
        width: 30vw;;
    }
}