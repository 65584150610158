.skills_hobbies_container {
    background-color: var(--black-three);
    color:white;
    padding: 5vh 5vw;
}

.skills_title {
    font-size: 3rem;
    padding-top: 0.5rem;
    text-align: center;
}

.skills_container {
    display: flex;
    flex-wrap: wrap;
    gap: 3vh 2vw;
    justify-content: space-around;
}

.skills_item {
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
    background-color: black;
    border-radius: 8px;
    padding: 5vh 1vw 1vw 1vw;
    width: 13vw;
    color: white;
    cursor: none;
}

.skills_and_tool_title {
    display: flex;
    padding-top: 3vh;
    padding-bottom: 1vh;
}

.skills_and_tool_title h4 {
    font-weight: bold;
    font-size: 1.5rem;
}

.svg_skill_container {
    align-self: center;
}

.svg_skill_container svg {
    font-size: 5vw;
}

._animated_active,
.skills_item:hover {
    transform: scale(1.06);
    color: black;
    transition: transform 1s ease-in-out;
}

.java_logo_animated_active,
.java_logo:hover {
    background-image: linear-gradient(to top right,  #007396, #FF0000);
}

.javascript_logo_animated_active,
.javascript_logo:hover {
    background-color: #F7DF1E;
}

.kotlin_logo_animated_active,
.kotlin_logo:hover {
    background-image: linear-gradient(to right, #7F52FF, #0095D5);
}

.python_logo_animated_active,
.python_logo:hover {
    background-image: linear-gradient(to top right,  #3CBB75, #FFE873);
}

.csharp_logo_animated_active,
.csharp_logo:hover {
    background-image: linear-gradient(to top right,  #68217A, #9B4F96);
}

.html_logo_animated_active,
.html_logo:hover {
    background-image: linear-gradient(to top left,  #E44D26, #F16529);
}

.css_logo_animated_active,
.css_logo:hover {
    background-image: linear-gradient(to top left,  #0073CF, #1E90FF);
}

.figma_logo_animated_active,
.figma_logo:hover {
    background-image: linear-gradient(to top right, #0ACF83, #1EA7FD, #FF7262, #A259FF);
}

.linux_logo_animated_active,
.linux_logo:hover {
    background-image: linear-gradient(to top right, #FED849, #555555, #EEEEEE);
}

.unity_logo_animated_active,
.unity_logo:hover {
    background-image: linear-gradient(to right, #666666, #CCCCCC, #FFFFFF);
}

.arduino_logo_animated_active,
.arduino_logo:hover {
    background-color:  #00979D;
}

.githubb_logo_animated_active,
.githubb_logo:hover {
    background-color: white;
}

.react_logo_animated_active,
.react_logo:hover {
    background-color: #61dafb;
}

.postgresql_logo_animated_active,
.postgresql_logo:hover {
    background-color: #336791;
}

.mysql_logo_animated_active,
.mysql_logo:hover {
    background-image: linear-gradient(to right, #4479a1, #336791, #ff7f00, #ffbf00);
}

.gitlab_logo_animated_active,
.gitlab_logo:hover {
    background-image: linear-gradient(to right, #FC6D26, #E24329, #FCA326, #FC6D26);
}

.docker_logo_animated_active,
.docker_logo:hover {
    background-image: linear-gradient(to right, #0db7ed, #384d54);
}

.nodejs_logo_animated_active,
.nodejs_logo:hover {
    background-image: linear-gradient(to right, #68a063, #3c873a);
}

.tailwind_logo_animated_active,
.tailwind_logo:hover {
    background-image: linear-gradient(to right, #3EA2B0, #A0D8D4);
}

.django_logo_animated_active,
.django_logo:hover {
    background-image: linear-gradient(to right, #306998, #FFD43B);
}

/* Mobile Phones */
@media screen and (max-width: 767px) {
    .skills_title {
        font-size: 2rem;
    }

    .skills_item {
        width: 40vw;
        align-items: center;
    }

    .svg_skill_container svg {
        font-size: 20vw;
    }

    .skills_and_tool_title h4 {
        text-align: center;
        font-size: 1.25rem;
    }
}

/* Tablets */
@media screen and (min-width: 768px) and (max-width: 1020px) {
    .skills_item {
        width: 25vw;
        align-items: center;
    }

    .svg_skill_container svg {
        font-size: 15vw;
    }
}