
@keyframes textAnimation {
    0% {
        color: #9500FF;
    }
    50% {
        color: #09F000;
    }
    100% {
        color: #00DDFF;
    }
}

@keyframes borderAnimation {
    0% {
        border-image-source: linear-gradient(150deg, #9500FF, #09F000, #00DDFF);
    }
    25% {
        border-image-source: linear-gradient(150deg, #00DDFF, #9500FF, #09F000);
    }
    50% {
        border-image-source: linear-gradient(150deg, #09F000, #00DDFF, #9500FF);
    }
    75% {
        border-image-source: linear-gradient(150deg, #9500FF, #09F000, #00DDFF);
    }
    100% {
        border-image-source: linear-gradient(150deg, #00DDFF, #9500FF, #09F000);
    }
}

#typewriter::after {
    content: "|";
    animation: typing 2s steps(20, end) infinite;
}

@keyframes typing {
    from {
        width: 0;
    }
}

@keyframes animationButton {
    0% { transform: scale(1); }
    50% { transform: scale(1.025); }
    100% { transform: scale(1); }
}

@keyframes pulse {
    0% {
        transform: scale(0);
    }
    20% {
        transform: scale(0.5);
    }
    40% {
        transform: scale(0.25);
    }
    60% {
        transform: scale(0.75);
    }
    80% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.5);
    }
}